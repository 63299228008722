<template>
  <b-container>
    <b-row>
      <b-col v-if="response" class="text-left">
          <h2 class="text-left">
          <b-icon class="mr-2" icon="google"></b-icon>
          <b-icon
            v-for="i in response.rating"
            :key="i"
            class="text-warning"
            icon="star-fill"
          ></b-icon>
          <span class="small ml-2">({{ response.user_ratings_total }})</span>
        </h2>
      </b-col>
    </b-row>
    <b-row>
        <b-card-group columns>
            <Card v-for="(comentario,index) in ordenarFecha(response.reviews)" :key="index" :comentario="comentario"></Card>
        </b-card-group>
    </b-row>
  </b-container>
</template>

<script>
import { getPlaceDataDummy } from "@/assets/apis/google/places.js";
import Card from "@/components/Cards/ValoracionCard";
const _ = require('lodash')
export default {
  components: {
    Card,
  },
  data() {
    return {
      response: null,
    };
  },
  methods: {
    getPlaceDataDummy,
    ordenarFecha(comentarios){
        let ordenados = _.orderBy(comentarios,['time'],["desc"]);
        return ordenados;
    }
  },
  async created() {
    this.response = await this.getPlaceDataDummy();
  },
};
</script>

<style>
</style>