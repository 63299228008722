const financiero = require('@/assets/functions/calculosFinancieros')
module.exports = {
    getCart() {
        let carroLocal = JSON.parse(localStorage.getItem("carrito"));
        if (carroLocal === null)
            carroLocal = [];
        return carroLocal
    },
    sumCart(carro){
        let suma = 0;
            carro.forEach(producto => {
                suma += producto.cantidad*producto.precio;
            });
            return suma;
    },
    updateProductCart(cart,productoAdd){
        let carroLocal = cart;
        let esta = false;
        carroLocal.forEach((prod) => {
            if (prod.id === productoAdd.id) {
                prod.cantidad=productoAdd.cantidad;
                esta = true;
                return true;
            }
        });
        if (!esta) carroLocal.push(productoAdd);
        localStorage.setItem("carrito", JSON.stringify(carroLocal));
    },
    deleteProductToCart(cart,productoDelete) {
        let carroLocal = cart;
        let index = 0;
        carroLocal.forEach((prod) => {
            if (prod.id === productoDelete.id) {
                carroLocal.splice(index,1)
            }
            index++
        });
        localStorage.setItem("carrito", JSON.stringify(carroLocal));
        return carroLocal;
    },
    addProductToCart(producto) {
        let productoAdd = {
            id: producto.id,
            nombre: producto.nombre,
            imagen: producto.imagenes[0].url,
            precio: financiero.aplicarDescuento(producto.precio, producto.descuento),
            codigo: producto.codigo,
            cantidad: 1,
        };
        let carroLocal = this.getCart();
        let esta = false;
        carroLocal.forEach((prod) => {
            if (prod.id === productoAdd.id) {
                prod.cantidad++;
                esta = true;
                return true;
            }
        });
        if (!esta) carroLocal.push(productoAdd);
        localStorage.setItem("carrito", JSON.stringify(carroLocal));
    }
}