<template>
  <div class="container">
    <!-- Nosotros, Misión, Visión  -->
    <div class="row my-2">
      <b-col cols="12">
        <h2 class="text-left">
          <b-icon class="mr-2" icon="file-earmark-person"></b-icon>Quiénes Somos
        </h2>
        <p class="bg-rosa text-justify p-5" id="quienes-somos">
          Somos Carol y Felipe, el 2014 iniciamos una pequeña aventura
          capacitándonos en el mundo de los productos para adultos, con la
          intensión de compartir nuestros conocimientos centramos nuestra pyme
          en asesorar a nuestra clientela y así ayudarle a disfrutar plenamente
          su sexualidad. En el 2016 dimos un paso al costado para centrarnos en
          nuestros estudios formales, sin embargo, el 2020 hemos vuelto con
          energías y una imagen renovada, una aún mejor atención y con una pyme
          formalizada, pero siempre con el mismo objetivo: empoderar a cada
          persona y pareja de su propia sexualidad.
        </p>
      </b-col>
      <!-- <b-col sm="12" md="4" lg="4">
        <h2 class="text-left">
          <b-icon class="mr-2" icon="bullseye"></b-icon>Misión
        </h2>
        <p class="bg-rosa text-justify p-5">
          Somos Carol y Felipe, el 2014 iniciamos una pequeña aventura
          capacitándonos en el mundo de los productos para adultos, con la
          intensión de compartir nuestros conocimientos centramos nuestra pyme
          en asesorar a nuestra clientela y así ayudarle a disfrutar plenamente
          su sexualidad. En el 2016 dimos un paso al costado para centrarnos en
          nuestros estudios formales, sin embargo, el 2020 hemos vuelto con
          energías y una imagen renovada, una aún mejor atención y con una pyme
          formalizada, pero siempre con el mismo objetivo: empoderar a cada
          persona y pareja de su propia sexualidad.
        </p>
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <h2 class="text-left">
          <b-icon class="mr-2" icon="binoculars"></b-icon>Visión
        </h2>
        <p class="bg-rosa text-justify p-5">
          Somos Carol y Felipe, el 2014 iniciamos una pequeña aventura
          capacitándonos en el mundo de los productos para adultos, con la
          intensión de compartir nuestros conocimientos centramos nuestra pyme
          en asesorar a nuestra clientela y así ayudarle a disfrutar plenamente
          su sexualidad. En el 2016 dimos un paso al costado para centrarnos en
          nuestros estudios formales, sin embargo, el 2020 hemos vuelto con
          energías y una imagen renovada, una aún mejor atención y con una pyme
          formalizada, pero siempre con el mismo objetivo: empoderar a cada
          persona y pareja de su propia sexualidad.
        </p>
      </b-col> -->
    </div>

    <!-- Galería de Colaboradores -->
    <div class="row my-2" id="equipo">
      <b-col cols="12">
        <h2 class="text-left">
          <b-icon class="mr-2" icon="person-bounding-box"></b-icon>Nuestro
          Equipo
        </h2>
      </b-col>
      <Colaboradores></Colaboradores>
    </div>

    <!-- Como Encontrarnos (Mapa, Dirección, Teléfono, etc) -->
    <div class="row my-2">
      <b-col cols="12">
        <h2 class="text-left">
          <b-icon class="mr-2" icon="person-lines-fill"></b-icon>Encuéntranos
        </h2>
      </b-col>

      <!--  Mapa -->
      <div class="col-sm-12 col-md-4 col-lg-4" sm="12" md="4" lg="4">
        <Mapa class="h-100 w-100" ></Mapa>
      </div>

      <!-- Datos de Contacto -->
      <b-col sm="12" md="4" lg="4">
        <b-card-text class="text-left small my-3">
          <p class="text-justify">
            <b-icon class="grande mr-2" icon="geo-alt"></b-icon>
            <b-link
              target="blank"
              class=".text-secondary"
              href="https://g.page/Sexduceme?share"
              >Parque Magallanes #1460, Bosque San Carlos, Coquimbo.</b-link
            >
          </p>
        </b-card-text>
        <div class=".text-secondary text-left">
          <b-row class="align-middle text-left">
            <b-card-text class="text-left my-3 col-2">
              <b-link
                target="blank"
                class=".text-secondary"
                href="https://wa.me/56997322024"
                ><font-awesome-icon
                  class="grande"
                  size="lg"
                  :icon="['fab', 'whatsapp']"
              /></b-link>
            </b-card-text>
            <b-col
              cols="8"
              class="h-100 text-sm-small text-md-large text-center"
              style="margin: 3% 0% 3% 0%"
            >
              <span class="">+56 9 9732 2024</span>
            </b-col>
            <b-card-text class="text-left my-3 col-2 text-left">
              <b-link
                target="blank"
                class=".text-secondary text-left"
                href="tel:+56997322024"
                ><b-icon class="grande" icon="telephone-forward"></b-icon
              ></b-link>
            </b-card-text>
          </b-row>
        </div>
      </b-col>

      <!-- Formulario de Contacto -->
      <b-col sm="12" md="4" lg="4">
        <Formulario></Formulario>
      </b-col>
    </div>

    <!-- Valoraciones de Google -->

    <div class="row my-2">
      <Valoraciones></Valoraciones>
    </div>
  </div>
</template>

<script>
import Colaboradores from "@/components/Galerias/ColaboradoresGallery";
import Mapa from "@/components/Integraciones/MapaGoogle";
import Formulario from "@/layout/FormularioContacto";
import Valoraciones from "@/components/Integraciones/Valoraciones";
export default {
  components: {
    Colaboradores,
    Mapa,
    Formulario,
    Valoraciones
  },
};
</script>