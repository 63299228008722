<template>
  <div>
    <b-form-radio-group
      v-model="selected"
      :options="options"
      class="mb-3 flex justify-content-between"
      value-field="id"
      text-field="nombre"
      disabled-field="deshabilitado"
      stacked
    ></b-form-radio-group>
  </div>
</template>

<script>
export default {
    props:['entrega'],
    data(){
        return {
            selected: 3,
            options: [
                {
                    id: 1,
                    nombre: "Efectivo",
                    descripcion: "Entrega de dinero en mano, ya sea con billetes o monedas",
                    deshabilitado: this["entrega"].value != 'retiro'
                },
                {
                    id: 2,
                    nombre: "Tarjeta",
                    descripcion: "Se aceptan tarjetas de entidades bancarias débito y crédito (1 cuota)",
                    deshabilitado: this["entrega"].value != 'retiro'
                },
                {
                    id: 3,
                    nombre: "Transferencia/Depósito",
                    descripcion: "Realiza una transferencia o depósito bancario a nuestra cuenta de Banco Estado y envíanos el comprobante (Debe pasar por un proceso de validación)",
                    deshabilitado: false
                },
                {
                    id: 4,
                    nombre: "webPay (Botón de Pago)",
                    descripcion: "Te enviaremos un botón de pago para realizar tu pago seguro con tus tarjetas de manera online",
                    deshabilitado: false
                },
                {
                    id: 5,
                    nombre: "Decidiré después",
                    descripcion: "",
                    deshabilitado: this["entrega"].value != 'retiro'
                },
            ]
        }
    }

}
</script>

<style>

</style>