<template>
<b-overlay
      :show="cargando"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-form-select
    :disabled="cargando"
    v-model="selected"
    :options="comunas"
  ></b-form-select>
</b-overlay>
</template>

<script>
const axios = require("@/assets/functions/axiosClient");
const _ = require('lodash')
export default {
  watch: {
    region: function (newValue) {
      this.getComunas(newValue);
    },
    selected: function (newValue) {
      this.$emit("seleccion", _.find(this.comunas,{value:newValue}));
    },
  },
  props: ["region"],
  data() {
    return {
      selected: null,
      cargando: false,
      comunas: [],
    };
  },
  methods: {
    async getComunas() {
      this.cargando = true;
      this.comunas = []
      axios
        .get(`/referenciales/geograficos/regiones/${this.region}/comunas`)
        .then((response) => {
          if (response.status === 200) {
            response.data.data.forEach((comuna) => {
              this.comunas.push({
                text: comuna.nombre,
                value: comuna.codigo,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
  },
  created() {
    this.getComunas();
  },
};
</script>

<style>
</style>