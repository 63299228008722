<template>
  <b-container>
    <b-row align-v="center">
      <b-col cols="12">
        <span>Región</span>
        <SelectRegion @seleccion="region = $event"></SelectRegion>
      </b-col>
      <b-col cols="12">
        <span>Comuna</span>
        <SelectComuna
          :region="region.value"
          @seleccion="comuna = $event"
        ></SelectComuna>
      </b-col>
      <b-col cols="12 pt-2">
        <vue-input-ui
          id="direccion"
          v-model="direccion"
          label=""
          size="lg"
          hint="Calle y Número"
          :disabled="!region || !comuna"
        >
        <template #hint>
            <span>Caca</span>
        </template>
        </vue-input-ui>
      </b-col>
      <b-col cols="12 pt-2">
        <b-alert :show="region.value != '04'" variant="info"
          >Los envíos a otras regiones son por pagar y se envían por un medio a
          convenir con el cliente (Starken, Chilexpress, otro)</b-alert
        >
      </b-col>
      <b-col cols="12 pt-2">
        <b-alert show variant="info" class="row">
          <b-col>{{direccionFull}}</b-col>
        </b-alert
        >
      </b-col>
      <b-col cols="12">
          <iframe class="w-100" :src="'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1413.7859226948235!2d-71.34015448175012!3d-29.947713985538734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9691c8540fed81ef%3A0xce5e56304dbc1739!2s'+direccionFull.replaceAll(' ','%20').replaceAll('#','')+'!5e0!3m2!1ses-419!2scl!4v1629303382213!5m2!1ses-419!2scl'"  allowfullscreen="true" loading="lazy"></iframe>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SelectRegion from "@/components/inputs/SelectRegion";
import SelectComuna from "@/components/inputs/SelectComuna";
import {formatPrice} from '@/assets/functions/formaters'
export default {
  components: {
    SelectRegion,
    SelectComuna,
  },
  watch:{
      region: function(){
          this.sendAddress()
      },
      comuna: function(){
          this.sendAddress()
      },

      direccion: function(){
          this.sendAddress()
      },
  },
  data() {
    return {
      region: {
        value: "04",
        text: "Coquimbo",
      },
      comuna: null,
      direccion: "",
      direccionFull: "",
    };
  },
  methods:{
      formatPrice,
      sendAddress(){
          this.direccionFull =  (this.direccion?this.direccion+", ":"")
                                +(this.comuna?this.comuna.text+", ":"")
                                +(this.region?this.region.text+", ":"") + "Chile";
        this.$emit('direccion',{    
            region: this.region,
            comuna: this.comuna,
            direccion: this.direccion,
            direccionFull: this.direccionFull,
            direccionComplete: (this.region && this.comuna && this.direccion.length>0)
        })
      }
  },
  mounted(){
      this.sendAddress();
  }
};
</script>

<style>
</style>