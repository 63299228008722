<template>
  <vue-input-ui
    v-model="inputEmail"
    label=""
    size="lg"
    hint="email@ejemplo.com"
    :error="isEmail===false"
    @blur="validarFormato()"
  ></vue-input-ui>
</template>

<script>
import { validateEmail } from "@/assets/functions/formaters";
export default {
  watch: {
    isEmail: function (newValue) {
      if(newValue)
        this.$emit('ingresado',this.inputEmail);
      else
        this.$emit('ingresado',"");
    },
    value: function(newValue){
        this.inputEmail = newValue
    }
  },
  props:[
      'value'
  ],
  data() {
    return {
      isEmail: null,
      inputEmail: "",
    };
  },
  methods: {
    validateEmail,
    validarFormato() {
      if (this.inputEmail.length > 0) {
        this.isEmail = this.validateEmail(this.inputEmail);
      }
      else {
        this.isEmail = null;
        }
    },
  },
};
</script>

<style>
</style>