<template>
  <div class="container">
    <div class="row">
      <Card
        class="col-sm-12 col-md-4 col-lg-4 col-xl-4"
        v-for="(colaborador, index) in equipo"
        :key="index"
        :colaborador="colaborador"
      ></Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Cards/ColaboradorCard";
export default {
  components: {
    Card,
  },
  data() {
    return {
      equipo: [
        {
          nombre: "Carol Toledo",
          cargo: "Asesora y Administradora",
          imagen:
            "https://i.ibb.co/k258w2k/97006024-161174265370835-6785164480243983052-n.jpg",
          // bio: "No importa cuantas cosas malas pueda escribir acá, mejor las callo, porque sino, ahí sí que no la cuento",
        },
        {
          nombre: "Felipe Robledo",
          cargo: "Asesor y Líder TI",
          imagen:
            "https://i.ibb.co/ZSMczkC/18096000-452791365067404-1628370372774592512-n.jpg",
          // bio: "Este wn sirve de poco y nada, pero bueno, es lo que hay po'... xD!",
        },
        // {
        //   nombre: "Arlet Olivares",
        //   cargo: "Asesora en Entrenamiento",
        //   imagen:
        //     "https://scontent.flsc2-1.fna.fbcdn.net/v/t1.18169-9/10402967_10204452780292009_1538837235968583965_n.jpg?_nc_cat=103&ccb=1-5&_nc_sid=a9b1d2&_nc_eui2=AeF4dMPXjy0HluCX-NSfA4bURVb5G9hu9E1FVvkb2G70TeL8OiDWaDo5JGpa8N8WQqQ&_nc_ohc=r1WNC-IKO9QAX_7MEu0&_nc_ht=scontent.flsc2-1.fna&oh=631cc138dc0c1923203ec92c988d7975&oe=613DCA95",
        //   // bio: "Nuestra posible primera trabajadora",
        // },
        // {
        //   nombre: "Andrés Bustamante",
        //   cargo: "Colaborador en Transporte",
        //   imagen:
        //     "https://scontent.flsc2-1.fna.fbcdn.net/v/t1.6435-9/31749520_1578355168929718_959379742694309888_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=174925&_nc_eui2=AeFK1Bikl_VNOqO3X5D1wSaTQmYOo8Cw2DhCZg6jwLDYOHEfW8_lYN33kbClSw6zdwk&_nc_ohc=MFpblaxd_MkAX8BHucc&_nc_ht=scontent.flsc2-1.fna&oh=1f3c9a924394043b4d7020ab1ce6ea66&oe=613E0B77",
        //   bio: "Así es, el mismísimo Trauco es parte de nuestro equipo (Trauco por la cacharrá de hijos más que por lo feo (aunque no son excluyentes xD!)",
        // },
        // {
        //   nombre: "Transportes \"Te Paseo\"",
        //   cargo: "Deliverys en Sucursal Limarí",
        //   imagen: "https://scontent.flsc2-1.fna.fbcdn.net/v/t1.18169-9/309425_2226014103926_219325288_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=174925&_nc_eui2=AeGFCRLhavKUwnLa05PlnwKcRR4e2LcW6XhFHh7YtxbpePSrRa9Fm0OfnF_IyF8t8eY&_nc_ohc=F1ROmR3xfIwAX_8M1g9&_nc_ht=scontent.flsc2-1.fna&oh=88f212b22729d469aff87a638c00fd5d&oe=613D0E36",
        //   bio: "Pyme encargada de realizar los deliverys de los pedidos realizado en el Valle del Limarí",
        // },
        // {
        //   nombre: "Delivery Racing",
        //   cargo: "Deliverys en Sucursal Coquimbo",
        //   imagen: "https://play-lh.googleusercontent.com/PB5j1XTB0BjykmMbydAptiXxXM19uGzzMPzH_blh-j0IuZFefBr2MEjJfWCoIZtstEg",
        //   bio: "Pyme encargada de realizar los deliverys de los pedidos realizado en Serena y Coquimbo",
        // }
      ],
    };
  },
};
</script>

<style>
</style>