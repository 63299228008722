<template>
<div>
    <div class="card fondo-rosa d-flex" :class="producto.stock<1 ? 'rosa-trans':''" >
        <Carousel :imagenes="producto.imagenes" :id="producto.id" origen="verticalCard"></Carousel>
        <h5 @click="verMas(producto.nombre,producto.codigo)" class="alert alert-danger">{{producto.nombre}}</h5>
        <div class="alert alert-danger" v-if="producto.stock<1">Producto Agotado</div>
        <div class="card-body">
            <p class="card-text precio " :class="producto.descuento>0 ? 'tachado' : ''">{{formatPrice(producto.precio)}}</p>
            <p class="card-text precio " v-if="producto.descuento>0">
                <b-avatar button @click="onClick" :text="'-'+producto.descuento+'%'" class="align-baseline text-white fondo-burdeo"></b-avatar>
                <!-- <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-star-fill fill-gold" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg> -->
                {{formatPrice(aplicarDescuento(producto.precio,producto.descuento))}}
            </p>
            <p>{{producto.descripcion.substring(0,100)}}... <i @click="verMas(producto.nombre,producto.codigo)" class="fa fa-eye seleccionable" aria-hidden="true"></i> </p>
            <div class="d-flex align-items-stretch">
            <!-- <p class="card-text descripcion">{{producto.descripcion}}</p> -->
            
            </div>
            <div>
            </div>
            
        </div>
        <!-- Botonera de Producto -->
            <div class="botonera-producto row">
                <!-- <a class="item-botonera col-6" target="blank" data-v-7b876761="">
                    <button @click="verMas(producto.nombre,producto.codigo)" class="btn btn-carro btn-block sombrear" data-v-7b876761="">
                    <i class="fa fa-plus"></i> Ver Más
                    </button>
                </a> -->
                <!-- AGREGAR AL CARRO -->
                <a class="item-botonera col-12" target="blank" data-v-7b876761="">
                    <addButton :producto = "producto" @accion="$emit('accion',$event)"></addButton>
                </a>
                <!-- COMPARTIR WHATSAPP -->
                <a class="item-botonera col-6" :href="'https://wa.me/?text=Mira este '+producto.nombre+' que encontré: '+obtenerLinkProducto(producto) " target="blank" data-v-7b876761="">
                    <button class="btn btn-wp btn-block" data-v-7b876761="">
                    <i class="fa fa-whatsapp"></i>
                    <i class="fa fa-share mx-1"></i>
                    </button>
                </a>
                <!-- COMPARTIR INSTAGRAM -->
                <!-- <a class="item-botonera col-4" target="blank" data-v-7b876761="">
                    <button class="btn btn-ig btn-block" data-v-7b876761="">
                    <i class="fa fa-instagram"></i> 
                    <i class="fa fa-share mx-1"></i>
                    </button>
                </a> -->
                <!-- COMPARTIR FACEBOOK -->
                <a class="item-botonera col-6" data-v-7b876761="" :href="'https://www.facebook.com/sharer/sharer.php?u='+obtenerLinkProducto(producto)" 
                onclick="window.open(this.href, 'mywin','left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;">
                    <button class="btn btn-fb btn-block" data-v-7b876761="">
                    <i class="fa fa-facebook"></i> 
                    <i class="fa fa-share mx-1"></i>
                    </button>
                </a>
            </div>
    </div>
</div>
</template>

<script>

// import Carousel from '@/components/Carousels/ProductoCarousel.vue';
import Carousel from '@/components/Carousels/CuadradoCarousel.vue';

import addButton from '@/components/inputs/ButtonAddProducto'
// import ProductoDetalle from '@/components/ProductoDetalle.vue'

export default {
    name: 'ProductoVertical',
    props:['producto'],
    components:{
        Carousel,
        addButton
        // ProductoDetalle
    },
    methods:{
        formatPrice(price){
            // return price;
            let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
            return '$ ' + priceString.substring(0,priceString.length-2);
        },
        aplicarDescuento(precio,descuento){
            let precioDescuento = precio-(precio*(descuento/100));
            return precioDescuento-(precioDescuento%10);
        },
        verMas(
            ){
            this.$emit('seleccionado',this.producto.codigo)
        },
        agregarCarro(){
            let productoAdd = {
                id: this.producto.id,
                nombre: this.producto.nombre,
                imagen: this.producto.imagenes[0].url,
                precio: this.aplicarDescuento(this.producto.precio,this.producto.descuento),
                codigo: this.producto.codigo,
                cantidad: 1
            }
            let carroLocal = JSON.parse(localStorage.getItem('carrito'));
            if(carroLocal===null)
                carroLocal = [];
            let esta = false
            carroLocal.forEach(prod => {
                if(prod.id===productoAdd.id){
                    prod.cantidad++;
                    esta = true;
                    return true;
                }
            });
            if(!esta)
                carroLocal.push(productoAdd);
            localStorage.setItem('carrito',JSON.stringify(carroLocal));
            document.getElementById('agregar-'+productoAdd.id).innerHTML=
            "<p>Producto Agregado al Carrito</p>";
            setTimeout(function(){
                document.getElementById('agregar-'+productoAdd.id).innerHTML=
                '<i class="fa fa-cart-plus"></i>';
            }, 2000);
        },
        obtenerLinkProducto(producto){
            return 'https://www.sexduceme.cl/producto/'+producto.codigo+'/'+producto.nombre.replaceAll(' ','_')
        }
    }
}
</script>

<style>
.bottom{
    position:relative;
    bottom:1;
}

.fill-gold{
    fill: rgb(246,202,68);
}

.botonera-producto{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
}

.descripcion{
    margin-bottom: 1px;
    width: 100%;
}

.sombrear{
    box-shadow: 0px -2px 16px 0px rgba(0,0,0,0.50);
}

.btn-carro .fa{
    font-size: xx-large;
}

.btn-carro p{
    font-family: inherit;
}

.fondo-rosa{
    background-color: rgb(253,226,237);
    color: black;
}
</style>