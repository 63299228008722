<template>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6912.586594009944!2d-71.25245059999999!3d-29.970999899999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9691cb2b0aa4bbf1%3A0xc77b77ebbc4dbfb0!2sSexduceme%20Sexshop!5e0!3m2!1ses-419!2scl!4v1628985245272!5m2!1ses-419!2scl" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</template>

<script>
export default {

    data(){
        return{
            apiKey: ""
        }
    }

}
</script>

<style>

</style>