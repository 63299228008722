<template>
<b-overlay :show="!cargando" rounded="sm">
    <div id="detalleProducto" class="row py-5" v-if="this.producto!=null">
        <head>
            <title>{{producto.nombre}}</title>
        </head>
        <div class="col-lg-6 col-12">
            <div class="col-12">
                <Carousel :imagenes="producto.imagenes" :id="producto.id" origen="detalle"></Carousel>
            </div>
            <div class="col-12">
                <a class="item-botonera col-12" :href="'https://wa.me/56997322024?text=Quisiera tener más información de este producto: '+producto.nombre+' (cod. '+producto.codigo+')'" target="blank" data-v-7b876761="">
                    <button class="btn btn-wp btn-block sombrear" data-v-7b876761="">
                    <i class="fa fa-whatsapp whatsapp-icon"></i> 
                    Preguntar este Producto
                    </button>
                </a>
            </div>
            <div class="col-12 my-1 d-flex justify-content-between">
                <!-- COMPARTIR WHATSAPP -->
                <a class="item-botonera col-6" :href="'https://wa.me/?text=Mira este '+producto.nombre+' que encontré: '+obtenerLinkProducto(producto)" target="blank" data-v-7b876761="">
                    <button class="btn btn-wp btn-block" data-v-7b876761="">
                    <i class="fa fa-whatsapp"></i>
                    <i class="fa fa-share mx-1"></i>
                    </button>
                </a>
                <!-- COMPARTIR INSTAGRAM -->
                <!-- <a class="item-botonera col-4" target="blank" data-v-7b876761="">
                    <button class="btn btn-ig btn-block" data-v-7b876761="">
                    <i class="fa fa-instagram"></i> 
                    <i class="fa fa-share mx-1"></i>
                    </button>
                </a> -->
                <!-- COMPARTIR FACEBOOK -->
                <a class="item-botonera col-6" data-v-7b876761="" :href="'https://www.facebook.com/sharer/sharer.php?u='+obtenerLinkProducto(producto)" 
                onclick="window.open(this.href, 'mywin','left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;">
                    <button class="btn btn-fb btn-block" data-v-7b876761="">
                    <i class="fa fa-facebook"></i> 
                    <i class="fa fa-share mx-1"></i>
                    </button>
                </a>
            </div>
            
        </div>
        <div class="col-lg-6 col-12">
            <div class="col-12">
                <h1 class="text-small">
                    {{producto.nombre}}
                </h1>
            </div>
            <div class="col-12 text-salto-linea text-justify">
                <p>
                    {{producto.descripcion}}
                </p>
            </div>
            <div class="col-12 text-salto-linea">
                <p class="text-left">
                    {{producto.detalles}}
                </p>
            </div>
            <div class="col-12">
                <p class="card-text precio " :class="producto.descuento>0 ? 'tachado' : ''">{{formatPrice(producto.precio)}}</p>
                <p class="card-text precio " v-if="producto.descuento>0">
                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-star-fill fill-gold" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                    {{formatPrice(aplicarDescuento(producto.precio,producto.descuento))}}
                </p>
                <!-- AGREGAR AL CARRO -->
                <a v-if="producto.stock>0" class="item-botonera col-6" target="blank" data-v-7b876761="">
                    <addButton class="btn-sexduceme" :producto = "producto"></addButton>
                </a>
            </div>
            <!-- PRODUCTOS RELACIONADOS -->
            <div class="col-lg-12 col-12">
                <div v-for="categoria in producto.categorias" :key="categoria.id" class="badge badge-primary text-wrap m-1" style="width: 6rem;">
                    {{categoria.nombre}}
                </div>
                <!-- <p>
                    <button @click="llenarTagGaleria(categoria.id)" v-for="categoria in producto.categorias" :key="categoria.id" class="btn btn-primary mx-1" type="button" data-toggle="collapse" data-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">{{categoria.nombre}}</button>
                </p> -->
            </div>
        </div>
        <div class="col-12" >
            <div class="col-12" v-for="producto in productosTags" :key="producto.id">
                <SimpleCardProducto :producto="producto" @seleccionado="buscarDetalle($event)"></SimpleCardProducto>
            </div>
        </div>
    </div>
</b-overlay>
</template>

<script>

// import Carousel from '@/components/Carousel.vue';
import Carousel from '@/components/Carousels/CuadradoCarousel.vue';

import SimpleCardProducto from '@/components/SimpleCardProducto.vue';
import addButton from '@/components/inputs/ButtonAddProducto'

window.$ = window.jQuery = require('jquery');

export default {
    name: 'ProductoDetalle',
    props: ['id'],
    watch:{
        id: function(newValue) {
            console.log(newValue)
            let codigo;
            codigo = newValue;
            const axios = require('axios').default;
            this.cargando = true;
            axios
            .get('https://api.sexduceme.cl/inventarios/productos/codigo/'+codigo)
            .then(response => {
                if(response.status===200){
                    this.producto = response.data.data;
                    document.title = this.producto.nombre;
                    document.description = this.producto.descripcion;
                    }
                })
                .catch(error=>{
                console.log(error);
                })
            .finally(()=>{
                this.cargando = false;
            }
            )
        }
    },
    components:{
        Carousel,
        SimpleCardProducto,
        addButton
    },
    methods:{
        topScroll(){
            this.$emit("top");
        },
        metaInfo(){
            return{
                title: this.producto.nombre
            }
        },
        buscarDetalle(codigo){
            this.topScroll();
            const axios = require('axios').default;
            this.cargando = true;
            axios
            .get('https://api.sexduceme.cl/inventarios/productos/codigo/'+codigo)
            .then(response => {
                this.producto = response.data.data;
                })
                .catch(error=>{
                console.log(error);
                })
            .finally(()=>{
                this.cargando = false;
            }
            )
        },
        llenarTagGaleria(idCategoria){
            if(idCategoria!=null){
            const axios = require('axios').default;
            this.cargando = true;
            axios.get('https://api.sexduceme.cl/inventarios/productos/categorias/'+idCategoria)
                .then(response => {
                this.productosTags = response.data.data;
                })
                .catch(error=>{
                    console.log(error);
                })
                .finally(()=>{
                this.cargando = false;
                }
                )
            }
        },
        formatPrice(price){
            // return price;
            let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
            return '$ ' + priceString.substring(0,priceString.length-2);
        },
        aplicarDescuento(precio,descuento){
            let precioDescuento = precio-(precio*(descuento/100));
            return precioDescuento-(precioDescuento%10);
        },
        obtenerLinkProducto(producto){
            return 'https://www.sexduceme.cl/producto/'+producto.codigo+'/'+producto.nombre.replaceAll(' ','_')
        },
        agregarCarro(){
            let productoAdd = {
                id: this.producto.id,
                nombre: this.producto.nombre,
                imagen: this.producto.imagenes[0].url,
                precio: this.aplicarDescuento(this.producto.precio,this.producto.descuento),
                codigo: this.producto.codigo,
                cantidad: 1
            }
            let carroLocal = JSON.parse(localStorage.getItem('carrito'));
            if(carroLocal===null)
                carroLocal = [];
            let esta = false
            carroLocal.forEach(prod => {
                if(prod.id===productoAdd.id){
                    prod.cantidad++;
                    esta = true;
                    return true;
                }
            });
            if(!esta)
                carroLocal.push(productoAdd);
            localStorage.setItem('carrito',JSON.stringify(carroLocal));
            document.getElementById('agregarDetalle-'+productoAdd.id).innerHTML=
            "<p>Producto Agregado al Carrito</p>";
            setTimeout(function(){
                document.getElementById('agregarDetalle-'+productoAdd.id).innerHTML=
                '<i class="fa fa-cart-plus"></i>';
            }, 2000);
        },
    },
    created(){
        let codigo;
        codigo = this.$route.params.codigo;
        if(!codigo){
            codigo = this.id
        }
        console.log("created: "+codigo)
        const axios = require('axios').default;
        this.cargando = true;
    axios
      .get('https://api.sexduceme.cl/inventarios/productos/codigo/'+codigo)
      .then(response => {
        this.producto = response.data.data;
        console.table(this.producto)
        })
        .catch(error=>{
          console.log(error);
        })
      .finally(()=>{
        this.cargando = false;
      }
      )
    },
    data(){
        return {
            producto:null,
            productosTags:[]
        }
    }
}
</script>

<style>
.text-salto-linea{
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -o-pre-wrap;
    }
</style>