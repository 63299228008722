<template>
  <b-card  :title="colaborador.nombre" img-top>
      <b-img-lazy thumbnail rounded="circle" :src="colaborador.imagen" alt="Image 8"></b-img-lazy>
      <b-card-text>
        {{colaborador.bio}}
      </b-card-text>
      <template #footer>
        <small class="text-muted">{{colaborador.cargo}}</small>
      </template>
    </b-card>
</template>

<script>
export default {
    props:["colaborador"]

}
</script>

<style>

</style>