<template>
  <div>
    <vue-input-ui
      type="text"
      v-model="cellphone"
      id="whatsapp"
      label=""
      size="lg"
      :hint="'+56 9 ' + spliteText(cellphone, 4)"
      :error="isPhone===false"
      @blur="validarFormato()"
    ></vue-input-ui>
  </div>
</template>

<script>
import { formatCellphone, spliteText } from "@/assets/functions/formaters";
export default {
  watch: {
    isPhone: function (newValue) {
        if(newValue)
        this.$emit('ingresado',"569" + this.cellphone);
      else
        this.$emit('ingresado',"");
    },
    cellphone: function (newValue) {
      this.cellphone = formatCellphone(newValue);
    },
    value: function(newValue){
        this.cellphone = newValue
    }
  },
  props:[
      'value'
  ],
  data() {
    return {
      cellphone: "",
      isPhone: null,
    };
  },
  methods: {
    formatCellphone,
    spliteText,
    validarFormato() {
      if (this.cellphone.length > 0) {
        this.isPhone = ("569"+this.cellphone).length===11;
      }
      else {
        this.isPhone = null;
        }
    },
  },
};
</script>

<style>
</style>