const axios = require("axios")
const placeId = 'ChIJ8bukCivLkZYRsL9NvOt3e8c'
module.exports = {
    getPlaceData() {
        console.log("En Módulo")
        let data;
        axios.get(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=rating,review,user_ratings_total,url,adr_address,formatted_address,international_phone_number,plus_code&key=AIzaSyCRYO4sxEdzEFCySAKWlAWD5r4bz8nfo0o&language=es-419`
            , {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "*/*",
                    "Accept": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Headers": "*"
                }
            })
            .then(response => {
                console.log("THEN")
                if (response.status === 200) {
                    data = response.data.result
                    data.statusCode = 200;
                }
            })
            .catch(error => {
                console.log("ERROR")
                data = {
                    ...error,
                    statusCode: 500
                }
            })

        return data;
    },
    async getPlaceDataDummy() {

        const auxResponse = await axios.get("https://api.sexduceme.cl/public/integraciones/google/bussiness/sexduceme/data")
        if (auxResponse.status === 200) {
            return auxResponse.data.result;
        }

        // return {
        //     "adr_address": "Bosque San Carlos - <span class=\"street-address\">Parque Magallanes #1460</span>, <span class=\"locality\">Coquimbo</span>, <span class=\"country-name\">Chile</span>",
        //     "formatted_address": "Bosque San Carlos - Parque Magallanes #1460, Coquimbo, Chile",
        //     "international_phone_number": "+56 9 9732 2024",
        //     "plus_code": {
        //         "compound_code": "2PHX+J2 Coquimbo, Chile",
        //         "global_code": "572C2PHX+J2"
        //     },
        //     "rating": 5,
        //     "reviews": [
        //         {
        //             "author_name": "María Olga",
        //             "author_url": "https://www.google.com/maps/contrib/108458916662948299646/reviews",
        //             "language": "es",
        //             "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14GjgVPa1cgCer0bhYwVlxRP5HK1VgbYDf6DG0rW6=s128-c0x00000000-cc-rp-mo",
        //             "rating": 5,
        //             "relative_time_description": "4 meses atrás",
        //             "text": "Excelente servicio..... Muy buen trato y, me encantó la discreción de la atención",
        //             "time": 1618177853
        //         },
        //         {
        //             "author_name": "Diego Roa",
        //             "author_url": "https://www.google.com/maps/contrib/105259184523354037931/reviews",
        //             "language": "es",
        //             "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14Gh04jBbfeNgp8OQ0zC9ll8guydLm-3lVQSqds18=s128-c0x00000000-cc-rp-mo",
        //             "rating": 5,
        //             "relative_time_description": "3 meses atrás",
        //             "text": "Muy buen servicio, estoy satisfecho con la compra, recomendado 100%",
        //             "time": 1619976806
        //         },
        //         {
        //             "author_name": "Francisco Angel",
        //             "author_url": "https://www.google.com/maps/contrib/114757667771925938351/reviews",
        //             "language": "es",
        //             "profile_photo_url": "https://lh3.googleusercontent.com/a/AATXAJwuWFGhV7-3RaqcCiSm_TsDG8n51OP1C5z8tZdY=s128-c0x00000000-cc-rp-mo",
        //             "rating": 5,
        //             "relative_time_description": "3 meses atrás",
        //             "text": "Muy buen servicio contestan rapido y muy buena atencion",
        //             "time": 1618954909
        //         },
        //         {
        //             "author_name": "Marianela Cortes",
        //             "author_url": "https://www.google.com/maps/contrib/107619760886488677661/reviews",
        //             "language": "es",
        //             "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14GizOkq24GXozyMl4_1h_klRvH0KqnU_Kwff-ZxaZg=s128-c0x00000000-cc-rp-mo",
        //             "rating": 5,
        //             "relative_time_description": "4 meses atrás",
        //             "text": "Excelente servicio super discreto 100% recomendable 🤭",
        //             "time": 1617205890
        //         },
        //         {
        //             "author_name": "Tania Alice",
        //             "author_url": "https://www.google.com/maps/contrib/116514743739176028977/reviews",
        //             "language": "es",
        //             "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14Ghs5Iddg8YJIaj2QPoqfh0tyTEYZhGPwwkxrzvSVw=s128-c0x00000000-cc-rp-mo",
        //             "rating": 5,
        //             "relative_time_description": "5 meses atrás",
        //             "text": "Variedad de productos. Muy confiables!!",
        //             "time": 1615655226
        //         }
        //     ],
        //     "url": "https://maps.google.com/?cid=14374214490043301808",
        //     "user_ratings_total": 30
        // }
    }
}