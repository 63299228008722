<template>
  <button
    :id="'agregar-' + this.producto.id"
    class="btn btn-carro btn-block sombrear"
    :class="!producto.stock > 0 ? 'desaparecer' : ''"
    @click="agregarCarro()"
  >
    <b-icon
    v-if="!agregando"
      icon="cart-plus"
      font-scale="2"
    ></b-icon>
    <b-icon
    v-if="agregando"
      icon="cart-plus-fill"
      animation="fade"
      font-scale="2"
    ></b-icon>
  </button>
</template>

<script>
import { aplicarDescuento } from "@/assets/functions/calculosFinancieros";
import { formatPrice } from "@/assets/functions/formaters";

export default {
  props: ["producto"],
  data() {
    return {
      count: 0,
      agregando: false
    };
  },
  methods: {
    agregarCarro() {
      let productoAdd = {
        id: this.producto.id,
        nombre: this.producto.nombre,
        imagen: this.producto.imagenes[0].url,
        precio: aplicarDescuento(this.producto.precio, this.producto.descuento),
        codigo: this.producto.codigo,
        cantidad: 1,
      };
      let carroLocal = JSON.parse(localStorage.getItem("carrito"));
      if (carroLocal === null) carroLocal = [];
      let esta = false;
      carroLocal.forEach((prod) => {
        if (prod.id === productoAdd.id) {
          prod.cantidad++;
          esta = true;
          return true;
        }
      });
      if (!esta) carroLocal.push(productoAdd);
      localStorage.setItem("carrito", JSON.stringify(carroLocal));
      this.agregando = true
      this.showToast();
      this.$emit('accion','refreshCart');
      setTimeout(() => {  this.agregando = false; }, 2900);
      
    },
    showToast() {
      const h = this.$createElement;
      this.count++;
      const vNodesMsg = h("p", { class: ["text-left", "mb-0"] }, [
        h("b-row", { props: {} }, [
          h("b-img-lazy", {
            class: ["col-4", "small"],
            props: { src: this.producto.imagenes[0].url, rounded: "circle" },
          }),
          h("p", { class: ["col-8"] }, [this.getEstadoCarro()]),
        ]),
      ]);
      this.$bvToast.toast([vNodesMsg], {
        title: this.producto.nombre + " Agregado al Carrito",
        variant: "success",
      });
    },
    getEstadoCarro() {
      let bultos = 0;
      let suma = 0;
      let carro = JSON.parse(localStorage.getItem("carrito"));
      if (carro === null) carro = [];

      carro.forEach((producto) => {
        bultos += producto.cantidad;
        suma += producto.cantidad * producto.precio;
      });
      return (
        "Total Productos en Carrito: " +
        bultos +
        " Total del Carrito: " +
        formatPrice(suma)
      );
    },
  },
};
</script>


<style>
</style>