<template>
  <div class="home container">
    <b-row>
      <b-col cols="12">
        <b-button
          @click="obtenerTodosProductos()"
          block
          size="sm"
          pill
          class="my-1 btn-sexduceme"
        >
          Todos los Productos
        </b-button>
      </b-col>
      <b-col
        col
        md="3"
        lg="2"
        v-for="categoria in categoriasPrincipales"
        :key="categoria.id"
      >
        <b-button
          @click="goToTiendaCategoria(categoria)"
          block
          size="sm"
          pill
          class="my-1 btn-sexduceme"
        >
          {{ categoria.nombre }}
        </b-button>
      </b-col>
      <b-col col md="3" lg="2">
        <b-button
          @click="mostrarCategorias()"
          block
          size="sm"
          pill
          class="my-1 btn-sexduceme"
          v-b-toggle.sidebar-footer
        >
        <b-icon icon="bookmark"></b-icon>
          Más Categorías
        </b-button>
      </b-col>
    </b-row>
    <Galeria
      @accion="$emit('accion', $event)"
      :class="this.cargando ? 'invisible' : 'mostrar'"
      :productos="productos"
      :mostrandoMensaje="mostrandoMensaje"
      :key='galleryKey'
    ></Galeria>
    <div v-if="this.cargando">
      <b-spinner type="grow" class="text-rosa mx-1" label="L"></b-spinner>
      <b-spinner type="grow" class="text-rosa mx-1"></b-spinner>
      <b-spinner type="grow" class="text-rosa mx-1"></b-spinner>
    </div>
    <div v-else-if="!this.productos.length > 0">
      {{ mensaje }}
    </div>

    <b-sidebar
      id="sidebar-footer"
      aria-label="Sidebar with custom footer"
      no-header
      shadow
    >
      <template #footer="{ hide }">
        <div class="d-flex fondo-rosa text-light align-items-center px-3 py-2">
          <b-button class="btn-sexduceme" size="sm" @click="hide">Cerrar</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <p class="text-burdeo text-justify">
          Aquí encontrarás todas las categorías disponibles en nuestra tienda, explora y disfruta... <b-icon icon="emoji-wink"></b-icon>
        </p>
        <!--
        <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img> -->
        <b-list-group>
          <b-list-group-item
            v-for="categoria in orderBy(categorias,['nombre'],['asc'])"
            :key="categoria.id"
            class="btn-sexduceme"
            @click="goToTiendaCategoria(categoria)"
            >{{ categoria.nombre }}</b-list-group-item
          >
        </b-list-group>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
// @ is an alias to /src
import Galeria from "@/components/Galerias/TiendaGaleria.vue";
import router from "@/router";
const {orderBy} = require('lodash');

const axios = require("axios").default;

export default {
  name: "Tienda",
  components: {
    Galeria,
  },
  data() {
    return {
      mostrandoMensaje: "",
      productos: [],
      categorias: [],
      categoriasPrincipales: [],
      cargando: false,
      mensaje: "",
      galleryKey:0
    };
  },
  methods: {
    orderBy,
    limpiarTexto(texto) {
      texto = texto.replace(/ |_/g, "");
      texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return texto.toUpperCase();
    },
    obtenerCategorias() {
      this.cargando = true;
      axios
        .get("https://api.sexduceme.cl/inventarios/categorias/")
        .then((response) => {
          if (response.status === 200) {
            this.categorias = response.data.data;
          } else if (response.status === 204) {
            this.mensaje = "No hay categorías en el sistema";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
          console.log(
            "Entró al else con: " + this.$route.params.categoria !== null
          );
          if (this.$route.params.categoria.length !== 0) {
            this.categorias.forEach((categoria) => {
              if (
                this.limpiarTexto(categoria.nombre) ==
                this.limpiarTexto(this.$route.params.categoria)
              ) {
                this.mostrandoMensaje =
                  'Estás viendo los productos de la categoría "' +
                  categoria.nombre +
                  '"';
                this.obtenerProductosCategoria(categoria.id);
              }
            });
          } else {
            this.obtenerTodosProductos();
          }
        });
    },
    obtenerProductos(categoriaId) {
      if (categoriaId === undefined) this.obtenerTodosProductos();
      else this.obtenerProductosCategoria(categoriaId);
    },
    busquedaProducto(busqueda) {
      this.productos = [];
      this.cargando = true;
      axios
        .get(
          "https://api.sexduceme.cl/inventarios/productos/busqueda/" + busqueda
        )
        .then((response) => {
          if (response.status === 200) {
            this.productos = response.data.data;
          } else if (response.status === 204) {
            this.mensaje = "No hay productos que concuerden con la busqueda.";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    obtenerProductosCategoria(categoriaId) {
      this.productos = [];
      this.cargando = true;
      axios
        .get(
          "https://api.sexduceme.cl/inventarios/productos/categorias/" +
            categoriaId
        )
        .then((response) => {
          if (response.status === 200) {
            this.productos = response.data.data;
          } else if (response.status === 204) {
            this.mensaje =
              "No hay productos que concuerden con la categoría indicada.";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    obtenerTodosProductos() {
      router.push('/tienda');
      this.mostrandoMensaje = "Estás viendo todos los productos disponibles";
      this.productos = [];
      this.cargando = true;
      this.cargando = true;
      axios
        .get("https://api.sexduceme.cl/inventarios/productos")
        .then((response) => {
          if (response.status === 200) {
            this.productos = response.data.data;
          } else if (response.status === 204) {
            this.mensaje = "No hay productos disponibles en la tienda.";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    goToTiendaCategoria(categoria) {
      router.push("/tienda/" + categoria.nombre);
      this.obtenerCategorias()
    },
    obtenerCategoriasPrincipales() {
      return [
        {
          id: 6,
          nombre: "Lubricantes",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 5,
          nombre: "Cosmética",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
        },
        {
          id: 1,
          nombre: "Juguetes",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
        },
        {
          id: 2,
          nombre: "Lencería",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
        },
        {
          id: 24,
          nombre: "Juegos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
      ];
    },
    mostrarCategorias() {
      if(!this.categorias.length>0)
        this.categorias = this.obtenerCategorias();
      
    },
    // Fin Methods
  },
  created() {
    this.categoriasPrincipales = this.obtenerCategoriasPrincipales();
  },
  mounted() {
    if (this.$route.query.busqueda) {
      console.log("En Busqueda");
      this.busquedaProducto(this.$route.query.busqueda);
      this.mostrandoMensaje =
        'Estás viendo los resultados de la Búsqueda "' +
        this.$route.query.busqueda.replaceAll("-", " ") +
        '"';
    } else if (this.$route.params.categoria) {
      this.categorias = this.obtenerCategorias();
    } else {
      this.obtenerTodosProductos();
    }
  },
};
</script>

<style>
.invisible {
  display: none;
}
.mostrar {
  display: block;
}
</style>