<template>
  <b-container class="bg-rosa text-white rounded py-5">
    <b-row>
      <b-col cols="12">
        <strong class="h3">Subtotal: {{ showTotalCarro(carro) }}</strong>
      </b-col>
      <b-col lg="12">
        <b-form-group
          label="Seleccione el Método de Entrega"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="plain-stacked"
            plain
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col v-if="selected === 'delivery'">
        <b-col cols="12">
          <span>Envíos a Todo Chile</span>
        </b-col>
        <SeleccionDireccion
          @direccion="direccion = $event"
        ></SeleccionDireccion>
        <b-row v-if="showDelivery()">
          <b-col cols="12 pt-2">
            <h5 class="text-left">
              <b-icon class="mr-2" icon="truck"></b-icon>Seleccione un medio de
              Envío
            </h5>
          </b-col>
          <b-col cols="12 pt-2">
            <SelectorDelivery
              :direccion="direccion"
              @envio="delivery = $event"
            ></SelectorDelivery>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-else-if="selected === 'retiro'">
        <!-- <b-alert show variant="info"
          >Puede Retirar en Bosque San Carlos, Coquimbo de <b>Martes a Sábado</b> desde
          las <b>10:00 hasta las 21:00</b></b-alert
        > -->
        <b-alert id="mensaje-retiro" show variant="info"
          >
          <!-- <p>Hasta el <b>Viernes 23</b> sólo podrás retirar tus pedidos en la <b>Feria de Emprendedores del Bosque San Carlos desde las 12:00 hasta las 23:00</b></p>
          <p> <b-alert show variant="warning"><b>Sábado 24 desde las 12:00 hasta las 18:00</b></b-alert></p> -->
          </b-alert
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12 py-2">
        <strong class="h3"
          >Total a Pagar: {{ formatPrice(getTotalAPagar()) }}</strong
        >
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <!-- <b-button
          @click="showModalValidar()"
          :disabled="!canPay()"
          block
          class="btn-sexduceme"
          >Solicitar Compra</b-button -->
          <b-button
          @click="solicitarCompra()"
          :disabled="!canPay()"
          block
          class="btn-sexduceme"
          >
          <font-awesome-icon size="lg" :icon="['fab', 'whatsapp']"/>
          Solicitar Compra</b-button
        >
        <!-- <b-button @click="setMensajeRetiro(getMensajeRetiro());">updateMessage</b-button> -->
      </b-col>
    </b-row>

    <ModalValidacion 
    :show="showModal"
    :carro="carro"
    :persona="persona"
    :delivery="{...getSelected(),...delivery,...direccion}"
     @cancel="showModal=false"
    ></ModalValidacion>
  </b-container>
</template>

<script>
import { getCart, sumCart } from "@/assets/functions/carroOperaciones";
import { formatPrice } from "@/assets/functions/formaters";
import SeleccionDireccion from "@/components/inputs/FormDireccion";
import SelectorDelivery from "@/components/inputs/FormMetodoEnvio";

import ModalValidacion from "@/components/Modals/DetalleCompraModal";
import axios from './../assets/functions/axiosClient';

const _ = require("lodash");
const calculos = require("@/assets/functions/calculosFinancieros");

export default {
  components: {
    SeleccionDireccion,
    SelectorDelivery,
    ModalValidacion,
  },
  watch: {
    region: function () {
      this.delivery = null;
    },
    comuna: function () {
      this.delivery = null;
    },
    direccion: function (newValue) {
      console.log("Dirección cambio: ");
      console.log(newValue);
      this.costoDelivery = null;
      this.delivery = null;
    },
    delivery: function (newValue) {
      this.costoDelivery = newValue.costoEnvio;
      console.log("Delivery cambio: ");
      console.log(newValue);
    },
  },
  data() {
    return {
      showModal: false,
      carro: [],
      direccion: null,
      medio: null,
      costoDelivery: null,
      delivery: null,
      selected: "retiro",
      persona: {
        nombre: null,
        cellphone: null,
        rut: null,
      },
      options: [
        { text: "Retiro", value: "retiro" },
        { text: "Delivery", value: "delivery" },
      ],
    };
  },
  methods: {
    ...calculos,
    formatPrice,
    sumCart,
    getCart,
    solicitarCompra(){
      let mensaje = this.pedirCarro();
      this.$ga.event('carro', 'solicitar', 'compras', 1);
      window.open("https://wa.me/56997322024?text=" + mensaje,'_blank');
    },
    pedirCarro() {
      
      let mensaje =
        "Me%20gustaría%20comprar%20los%20siguientes%20productos:%0A";
      this.carro.forEach((producto) => {
        mensaje +=
          "%0A" +
          producto.cantidad +
          "%20" +
          producto.nombre +
          "%20(cod:%20*" +
          producto.codigo +
          "*)";
      });

      mensaje += "%0A%0AMedio de entrega: "+this.getSelected().text
      mensaje += "%0ADirección de Entrega: "+(this.getSelected().value==='retiro'?"Parque Magallanes 1460, Bosque San Carlos, Coquimbo":this.direccion.direccionFull)
      return mensaje;
    },
    reservar(carro, entrega, persona, pago) {
      console.log(carro);
      console.log(entrega);
      console.log(persona);
      console.log(pago);
    },
    getSelected() {
      return _.find(this.options, { value: this.selected });
    },
    showDelivery() {
      return this.direccion
        ? this.direccion.region &&
            this.direccion.region &&
            this.direccion.direccion
        : false;
    },
    showTotalCarro(carro) {
      return this.formatPrice(this.sumCart(carro));
    },
    canPay() {
      if (
        this.selected === "retiro" ||
        ((this.direccion ? this.direccion.direccionComplete : false) &&
          (this.delivery ? this.delivery.medioComplete : false))
      )
        return true;
      else return false;
    },
    showModalValidar() {
        console.log(this.getSelected())
        console.log(this.delivery)
      this.showModal = true
    },
    getTotalAPagar() {
      return this.sumCart(this.carro) + this.costoDelivery;
    },
    setMensajeRetiro(mensaje){
      const elemento = document.getElementById("mensaje-retiro");
      console.log(elemento.innerHTML)
      elemento.innerHTML = mensaje;
    },
    async getMensajeRetiro(){
      let auxResponse = await axios.get("/plantillas/retiros/casa-matriz")
      if(auxResponse.status === 200){
        console.log(auxResponse.data)
        return auxResponse.data.data;
      } else {
        return ""
      }
    }
  },
  created() {
    this.carro = this.getCart();
  },
  async mounted(){
    this.setMensajeRetiro(await this.getMensajeRetiro());
  }
};
</script>

<style>
</style>