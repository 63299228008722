<template>
  <b-container>
    <b-row class="bg-rosa py-2">
      <b-col sm="12" md="8" class="text-center">
        <Buscador class="h-100"></Buscador>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="text-left">
        <p>
          Ordenar
          <b-button @click="setOrderBy('nombre')" class="btn-sexduceme">
            <b-icon class="mx-2" icon="type"></b-icon>
            <b-icon
              v-if="orderBy.campo === 'nombre' && orderBy.descendente"
              icon="arrow-down-circle"
            ></b-icon>
            <b-icon
              v-if="orderBy.campo === 'nombre' && !orderBy.descendente"
              icon="arrow-up-circle"
            ></b-icon>
          </b-button>
          <b-button @click="setOrderBy('precio')" class="btn-sexduceme">
            <b-icon class="mx-2" icon="cash"></b-icon>
            <b-icon
              v-if="orderBy.campo === 'precio' && orderBy.descendente"
              icon="arrow-down-circle"
            ></b-icon>
            <b-icon
              v-if="orderBy.campo === 'precio' && !orderBy.descendente"
              icon="arrow-up-circle"
            ></b-icon>
          </b-button>
          <b-button @click="setOrderBy('descuento')" class="btn-sexduceme">
            <b-icon class="mx-2" icon="tags"></b-icon>
            <b-icon
              v-if="orderBy.campo === 'descuento' && orderBy.descendente"
              icon="arrow-down-circle"
            ></b-icon>
            <b-icon
              v-if="orderBy.campo === 'descuento' && !orderBy.descendente"
              icon="arrow-up-circle"
            ></b-icon>
          </b-button>
        </p>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="text-left">
        <span>{{ mostrandoMensaje }} ({{this_productos.length}})</span>
      </b-col>
    </b-row>

    <!-- LISTAR PRODUCTOS -->
    <div class="card-deck row row-cols-1 row-cols-md-3" id="galery">
      <div
        class="col mb-4 align-items-stretch"
        :class="producto.stock < 1 ? 'desaparecer' : ''"
        v-for="producto in this_productos.slice(
          (currentPage - 1) * perPage,
          (currentPage - 1) * perPage + perPage
        )"
        :key="producto.id"
      >
        <ProductoVertical
          :producto="producto"
          @seleccionado="mostrarModal($event)"
          @accion="$emit('accion', $event)"
        ></ProductoVertical>
      </div>
    </div>

    <div>
      <b-modal
        class="h-100"
        lazy
        size="xl"
        ok-only
        ok-title="Listo"
        v-model="modalShow"
      >
        <ProductoDetalle :id="seleccionado"></ProductoDetalle>
      </b-modal>
    </div>
    <b-row v-if="rows > 0">
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          size="sm"
          pills
          align="fill"
          limit="10"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ProductoVertical from "@/components/Cards/ProductoVertical.vue";
import ProductoDetalle from "@/components/ProductoDetalle";
import Buscador from "@/components/inputs/Buscador";
window.$ = window.jQuery = require("jquery");
const _ = require("lodash");
export default {
  name: "Galeria",
  props: ["productos", "mostrandoMensaje"],
  components: {
    ProductoVertical,
    ProductoDetalle,
    Buscador,
  },
  watch: {
    productos: function (newValue) {
      this.this_productos = newValue;
      this.setOrderBy(this.orderBy.campo);
    },
    currentPage: function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 12,
      orderBy: {
        campo: null,
        descendente: null,
      },
      this_productos: [],
      modalShow: false,
      seleccionado: null,
      showAgotados: false,
      ascendente: true,
      ordenarPor: 3,
      categorias: [],
    };
  },
  methods: {
    scrollModalTop() {
      window.$("#modalDetalle").on("shown.bs.modal", function () {
        window.$("#modalDetalle").animate({ scrollTop: 0 }, "slow");
      });
    },
    mostrarModal(productoId) {
      this.seleccionado = productoId;
      this.modalShow = true;
    },
    setOrderBy(campo) {
      if (!campo) {
        this.orderBy.campo = "precio";
        this.orderBy.descendente = true;
      } else if (this.orderBy.campo === campo) {
        this.orderBy.descendente = !this.orderBy.descendente;
      } else {
        this.orderBy.campo = campo;
      }
      this.this_productos = _.orderBy(
        this.this_productos,
        [this.orderBy.campo],
        [this.orderBy.descendente ? "desc" : "asc"]
      );
      console.log(this.this_productos);
    },
    formatPrice(price) {
      let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
      return "$ " + priceString.substring(0, priceString.length - 2);
    },
    aplicarDescuento(precio, descuento) {
      return precio - precio * (descuento / 100);
    },
    mostrarAgotados() {
      this.showAgotados = !this.showAgotados;
    },
    categoriaFiltrada(id) {
      let categoria = this.categorias.find((categoria) => {
        return categoria.id === id;
      });
      categoria.checked = !categoria.checked;
    },
    seleccionarUno(id) {
      this.categorias.forEach((categoria) => {
        if (categoria.id === id) categoria.checked = true;
        else categoria.checked = false;
      });
    },
    getCategorias() {
      return [
        {
          id: 14,
          nombre: "Accesorios",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 3,
          nombre: "Lencería Masculina",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 4,
          nombre: "Lencería Femenina",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 6,
          nombre: "Lubricantes",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 8,
          nombre: "Estimulantes",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 9,
          nombre: "Potenciadores",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 10,
          nombre: "Vibradores",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 11,
          nombre: "Balas Vibradoras",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 12,
          nombre: "Consoladores",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 13,
          nombre: "Anillos Peneanos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 15,
          nombre: "Rotadores",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 16,
          nombre: "Masturbadores Masculinos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 18,
          nombre: "Feromonas",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 23,
          nombre: "Sexo Oral",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 5,
          nombre: "Cosmética",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
        },
        {
          id: 1,
          nombre: "Juguetes",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
        },
        {
          id: 2,
          nombre: "Lencería",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
        },
        {
          id: 7,
          nombre: "Masajes",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
        },
        {
          id: 20,
          nombre: "Para Ellas",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
        },
        {
          id: 19,
          nombre: "Para Ellos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
        },
        {
          id: 21,
          nombre: "Para Parejas",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 17,
          nombre: "Sexo Anal",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 24,
          nombre: "Juegos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 25,
          nombre: "Unisex",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 26,
          nombre: "Retardantes Masculinos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 28,
          nombre: "Arnés",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 29,
          nombre: "Preservativos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 30,
          nombre: "Prostáticos",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 32,
          nombre: "BDSM",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
        },
        {
          id: 22,
          nombre: "Kits",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 34,
          nombre: "Ofertas Relámpago",
          descripcion: null,
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
        {
          id: 36,
          nombre: "Monte Patria",
          descripcion:
            "Productos disponibles para entrega inmediata en la ciudad de Monte Patria, Cuarta Región",
          imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
        },
      ];
    },
    onInput(e) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          q: e.target.value,
        },
      });
    },
  },
  created() {
    this.categorias = this.getCategorias();
    this.this_productos = this.productos;
  },
  computed: {
    rows() {
      return this.this_productos.length;
    },
  },
};
</script>

<style>
.precio {
  color: black;
  font-size: large;
}

/* .precio:hover{
    color: purple;
} */

.card img {
  padding: 0%;
  border-radius: 0%;
}

.fondo-burdeo {
  background-color: #740b33;
  color: #f8afcc;
}

.titulo {
  font-size: large;
  height: 5%;
  background-color: #f8afcc;
  color: #740b33;
  border-style: solid;
  border-color: #740b33;
}

.redondear {
  border-radius: 0%;
}

.bg-rosa {
  background-color: #f8afcc;
  color: #740b33;
}

.btn.bg-rosa:hover {
  background-color: #bb1253;
  color: #f8afcc;
}

a:hover {
  text-decoration: none;
}

.tachado {
  text-decoration: line-through;
}

.desaparecer {
  display: none;
}

.gris {
  /* grayscale */
  -webkit-filter: grayscale(1);
  filter: gray;
  filter: grayscale(1);

  /* multiply */
  background-color: rgba(190, 50, 50, 0.65);
  background-blend-mode: multiply;
}

.seleccionable:hover {
  background-color: #f8afcc;
  color: #740b33;
  cursor: pointer;
}

.central-vertical {
  align-items: center;
  vertical-align: middle;
}
.rosa-trans {
  /* grayscale */
  -webkit-filter: grayscale(1);
  filter: #f8afcc;

  /* multiply */
  background-color: rgba(253, 225, 234, 0.2);
  background-blend-mode: multiply;
  color: #740b33 !important;
}

.rosa-trans > .card-body > .precio {
  color: #740b33;
}

.font-large {
  font-size: xx-large;
}

.enmarcar {
  border: solid;
  border-width: 1px;
  padding: 1%;
}

.simplescrollup__button {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  transition: 1s all;
  z-index: 10000;
}
.simplescrollup__button--show {
  transform: translateX(0);
}
.simplescrollup__button--hide {
  transform: translateX(100px);
}
</style>