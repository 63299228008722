<template>
  <div>
    <vue-input-ui
      type="text"
      v-model="rut"
      id="rut"
      label=""
      size="lg"
      hint='Rut'
      :error="isRut===false"
      @blur="validarFormato()"
    ></vue-input-ui>
  </div>
</template>

<script>
import {  validate, format } from 'rut.js'

export default {
  watch: {
    isRut: function (newValue) {
        if(newValue)
        this.$emit('ingresado',this.rut);
      else
        this.$emit('ingresado',"");
    },
    rut: function (newValue) {
      this.rut = format(newValue);
    },
    value: function(newValue){
        this.rut = newValue
    }
  },
  props:[
      'value'
  ],
  data() {
    return {
      rut: "",
      isRut: null,
    };
  },
  methods: {
    validarFormato() {
      if (this.rut.length > 0) {
        this.isRut = validate(this.rut);
      }
      else {
        this.isRut = null;
        }
    },
  },
};
</script>

<style>
</style>