<template>
  <b-card
    :title="comentario.author_name.split(' ')[0]"
  >
    <b-card-text>
      {{ comentario.text }}
    </b-card-text>
    <template #footer>
      <small class="text-muted"
        ><b-row>
          <b-col sm="12" md="6" lg="6" class="text-left">
            <b-icon
              v-for="i in comentario.rating"
              :key="i"
              class="text-warning"
              icon="star-fill"
            ></b-icon>
          </b-col>
          <b-col sm="12" md="6" lg="6" class="text-right">
            {{ unixForDate(comentario.time) }}
          </b-col>
        </b-row></small
      >
    </template>
  </b-card>
</template>

<script>
const moment = require("moment");
export default {
  props: ["comentario"],
  methods: {
    moment,
    unixForDate(timestamp) {
      return moment.unix(timestamp).format("DD-MM-yyyy");
    },
  },
};
</script>

<style>
</style>