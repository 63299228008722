<template>
  <b-container>
    <b-row>
      <b-form-radio-group
        v-model="selected"
        :options="medios"
        class="mb-3"
        value-field="id"
        text-field="nombre"
        disabled-field="notEnabled"
      ></b-form-radio-group>
    </b-row>
    <b-row v-if="getMedioSelected() ? getMedioSelected().cotizable : false">
      <b-col cols="12 pt-2 row">
        <b-col md="12">
          <b-button block pill @click="cotizarDelivery()" class="btn-sexduceme">
            <span v-if="!cargando">
              Cotizar Delivery{{
                costoDelivery ? ": " + formatPrice(costoDelivery) : ""
              }}
            </span>
            <b-spinner small v-else label="Loading..."></b-spinner>
          </b-button>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const axios = require("@/assets/functions/axiosClient");
import { formatPrice } from "@/assets/functions/formaters";
const _ = require("lodash");
export default {
  props: ["direccion"],
  watch: {
    direccion: function (newValue) {
      console.log("comuna: " + newValue.comuna);
      this.medios = this.getMediosByComuna(newValue.comuna.value);
      this.costoDelivery = null;
      this.selected = null;
    },
    selected: function () {
      let medio = this.getMedioSelected();
      console.log("Seleccionado: ");
      console.log(medio);
      if (!medio.cotizable) this.sendMedio();
    },
    costoDelivery: function (newValue) {
      console.log(newValue);
      this.sendMedio();
    },
  },
  components: {},
  data() {
    return {
      costoDelivery: null,
      cargando: false,
      selected: null,
      medios: [],
    };
  },
  methods: {
    formatPrice,
    cotizarDelivery() {
      this.cargando = true;
      axios
        .get(
          "/public/integraciones/google/bussiness/sexduceme/deliverys-cost/" +
            this.direccion.direccionFull.replace("#", "")
        )
        .then((response) => {
          if (response.status === 200) {
            this.costoDelivery = response.data.costo;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    getMedioSelected() {
      return _.find(this.medios, { id: this.selected });
    },
    getMediosByComuna(comuna) {
      console.log("Vamos a buscar medios para " + comuna);
      this.selected = null;
      // const deliveryRacing = {
      //   id: 1,
      //   nombre: "Delivery Racing",
      //   descripcion:
      //     "Pyme de deliverys que abarca la zona urbana de La Serena y Coquimbo.",
      //   web: "https://www.instagram.com/delivery_racing/",
      //   cotizable: true,
      // };

      const deliemprende = {
        id: 5,
        nombre: "Delivery deliemprende",
        descripcion:
          "Pyme de deliverys que abarca la zona urbana y Rural de La Serena y Coquimbo.",
        web: "https://deliemprende.cl/",
        cotizable: true,
      };

      const tacso = {
        id: 2,
        nombre: "TACSO",
        descripcion:
          "Empresa de taxis colectivos interurbanos que transporta paquetería hasta sus destinos.",
        web: "https://www.facebook.com/encomiedas.ovalle.laserena",
        cotizable: false,
      };

      const chilexpress = {
        id: 3,
        nombre: "Chilexpress",
        descripcion:
          "Empresa dedicada la transporte de paquetería a nivel nacional.",
        web: "https://www.chilexpress.cl/",
        cotizable: false,
      };

      const starken = {
        id: 4,
        nombre: "Starken",
        descripcion:
          "Empresa dedicada la transporte de paquetería a nivel nacional.",
        web: "https://www.starken.cl/",
        cotizable: false,
      };

      // Serena y Coquimbo
      if (comuna === "04101" || comuna === "04102") {
        return [deliemprende];
      }
      // Ovalle
      else if (comuna === "04301") {
        return [tacso, starken, chilexpress];
      }
      // Vicuña
      else if (comuna === "04106") {
        return [tacso, starken, chilexpress];
      }
      // Cualquier otra
      else {
        return [starken, chilexpress];
      }
    },
    sendMedio() {
      let medio = this.getMedioSelected();
      let medioComplete = false;
      if(this.selected){
          if(medio.cotizable===true){
              if(this.costoDelivery>0){
                  medioComplete = true;
              }
              else medioComplete = false
          }
          else {
              medioComplete = true;
          }
      }
      else{
          medioComplete = false;
      }
      this.$emit("envio", {
        costoEnvio: medio ? (medio.cotizable ? this.costoDelivery : 0) : null,
        medio,
        medioComplete
      });
    },
  },
  mounted() {
    this.medios = this.getMediosByComuna(this.direccion.comuna.value);
  },
};
</script>

<style>
</style>