<template>
  <div class="container" v-if="!carro.length > 0">
    <b-alert :show="!carro.length > 0" variant="success">
      <b-icon icon="emoji-frown"> </b-icon>

      <span> No has agregado ningún producto a tu Carrito... </span>
    </b-alert>
    <b-row class="text-left my-2">
      <b-link to="/tienda" class="col-4">
        <b-icon class="mr-2" icon="cart2"></b-icon>
        <span>¿Veamos que hay Disponible?</span>
      </b-link>
      <span class="col-8"
        >Conoce todos los productos que se encuentran disponibles para entrega
        inmediata</span
      >
    </b-row>
    <b-row class="text-left my-2">
      <b-link target="blank" href="https://wa.me/56997322024?text=Hola, me podría ayudar a elegir qué productos necesito?" class="col-4">
        <b-icon class="mr-2" icon="headset"></b-icon>
        <span>¿Necesitas Asesoría?</span>
      </b-link>
      <span class="col-8">Recibe Asesoría de nuestros colaboradoras/es </span>
    </b-row>
    <b-row class="text-left my-2">
      <b-link to="/politicas" class="col-4">
        <b-icon class="mr-2" icon="patch-question"></b-icon>
        <span>¿Cómo se Compra?</span>
      </b-link>
      <span class="col-8"
        >Conoce nuestras políticas de venta, entrega y delivery</span
      >
    </b-row>
  </div>

  <b-container v-else>
    <b-row>
      <b-col lg="12" v-for="(producto, index) in carro" :key="producto.id">
        <Card
          @accion="refreshCarro()"
          @update="refreshCarro()"
          :producto="producto"
          :index="index"
          onlyDelete="true"
        ></Card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Card from "@/components/Cards/ProductoCarroCard";
import { getCart } from "@/assets/functions/carroOperaciones";

export default {
  components: {
    Card,
  },
  data() {
    return {
      carro: [],
    };
  },
  methods: {
    refreshCarro() {
      this.carro = getCart();
      this.$emit('accion','refresh')
    },
  },
  created(){
    this.refreshCarro();
  }
};
</script>

<style>
</style>