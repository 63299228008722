<template>
  <b-container>
    <b-row>
      <b-col lg="7" md="6" sm="12">
        <GaleriaCarro @accion="refresh()"></GaleriaCarro>
      </b-col>
      <b-col lg="5" md="6" sm="12">
        <DetalleCarro :key="updating"></DetalleCarro>
      </b-col>
    </b-row>

    <!-- <div
      v-if="carro.length > 0"
      class="row row-cols-1 row-cols-md-4 d-flex justify-content-end"
    >
      <button
        class="btn btn-danger mr-3 btn-block"
        @click="vaciarCarro()"
        data-v-7b876761=""
      >
        <i class="fa fa-trash"></i>
        Vaciar Carro
      </button>
      <a
        class="item-botonera col-12"
        :href="'https://wa.me/56997322024?text=' + this.pedirCarro()"
        :target="this.esMovil() ? '' : 'blank'"
        data-v-7b876761=""
      >
        <button class="btn btn-wp btn-block" data-v-7b876761="">
          <i class="fa fa-whatsapp d-flex-right whatsapp-icon"></i>
          Solicitar esta Compra ({{ formatPrice(sumarCarro()) }})
        </button>
      </a>
    </div> -->
  </b-container>
</template>

<script>
import GaleriaCarro from "@/components/Galerias/CarroGallery";
import DetalleCarro from "@/layout/DetalleCarro";
export default {
  name: "CarroCompra",
  components: {
    GaleriaCarro,
    DetalleCarro,
  },
  data(){
    return {
      updating:0
    }
  },
  methods: {
    formatPrice(price) {
      // return price;
      let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
      return "$ " + priceString.substring(0, priceString.length - 2);
    },
    vaciarCarro() {
      this.carro = [];
      localStorage.setItem("carrito", JSON.stringify(this.carro));
    },
    pedirCarro() {
      let mensaje =
        "Me%20gustaría%20comprar%20los%20siguientes%20productos:%0A";
      this.carro.forEach((producto) => {
        mensaje +=
          "%0A" +
          producto.cantidad +
          "%20" +
          producto.nombre +
          "%20(cod:%20*" +
          producto.codigo +
          "*)";
      });
      return mensaje;
    },
    refresh(){
      console.log("woamo a refresh")
      this.updating++;
      this.$emit('accion','refreshCart');
      this.$forceUpdate()
    },
    sumarCarro() {
      let suma = 0;
      this.carro.forEach((producto) => {
        suma += producto.cantidad * producto.precio;
      });
      return suma;
    },
    quitarCarro(index) {
      this.carro.splice(index, 1);
      localStorage.setItem("carrito", JSON.stringify(this.carro));
    },
    esMovil() {
      return (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/BlackBerry/i)
      );
    },
  },

  metaInfo() {
    return {
      title: "Carro de Compra - Sexdúceme",
    };
  },
};
</script>

<style>
.img-small {
  height: auto;
  width: 100px;
}
tr td {
  vertical-align: middle !important;
}
</style>