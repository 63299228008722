<template>
  <b-container class="border border-solid block">
    <b-row align-v="center">
      <b-col cols="3">
        <b-row>
          <b-img-lazy thumbnail :src="producto.imagen"> </b-img-lazy>
        </b-row>
        <b-row v-if="onlyDelete">
          <b-button @click="deleteFromCart()" size="sm" block variant="danger"
            ><b-icon icon="trash"></b-icon
          ></b-button>
        </b-row>
      </b-col>
      <b-col cols="2">
        <b-form-spinbutton
          id="sb-vertical"
          v-model="cantidad"
          vertical
        ></b-form-spinbutton>
      </b-col>
      <b-col cols="3">
        <span class="small">{{ formatPrice(producto.precio) }} c/u</span>
      </b-col>
      <b-col cols="3">
        <span class="small">{{ formatPrice(producto.precio * cantidad) }}</span>
      </b-col>
      <b-col cols="1" v-if="!onlyDelete">
        <b-row v-if="b - share" class="my-2">
          <b-button @click="deleteFromCart()" size="sm" block variant="info"
            ><b-icon icon="share"></b-icon
          ></b-button>
        </b-row>
        <b-row class="my-2">
          <b-button @click="deleteFromCart()" size="sm" block variant="danger"
            ><b-icon icon="trash"></b-icon
          ></b-button>
        </b-row>
        <b-row v-if="b - see" class="my-2">
          <b-button @click="deleteFromCart()" size="sm" block variant="success"
            ><b-icon icon="eye"></b-icon
          ></b-button>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { formatPrice } from "@/assets/functions/formaters";
const operaciones = require("@/assets/functions/carroOperaciones");
export default {
  props: ["producto", "index", "onlyDelete", "b-trash", "b-share", "b-see"],
  watch: {
    cantidad: function (newValue) {
      this.producto.cantidad = newValue;
      operaciones.updateProductCart(operaciones.getCart(), this.producto);
      this.$emit('update',true);
    },
  },
  data() {
    return {
      cantidad: 1,
    };
  },
  methods: {
    formatPrice,
    deleteFromCart() {
      operaciones.deleteProductToCart(operaciones.getCart(), this.producto);
      this.$emit("accion", "deleted");
    },
  },
  created() {
    this.cantidad = this.producto.cantidad;
  },
};
</script>

<style>
</style>