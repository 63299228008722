<template>
  <b-container>
    <b-row>
      <b-col sm="12" lg="6" xl="4" class="bg-rosa p-4 text-white">
        <h4><b-icon icon="cart4" class="mr-2"></b-icon>Políticas de Compra</h4>
        <p class="text-justify">
          La solicitud de una compra puede realizarse por cualquiera de los
          canales digitales disponibles (incluido este sitio web), una vez
          realizada la solicitud de compra esta pasará a un proceso de
          verificación de stock y se te comunicará el detalle del pedido y su
          respectivo total.
        </p>
        <p class="small text-justify">
          El proceso de verificación de stock no debiese tomar más de algunos
          minutos.
        </p>
      </b-col>
      <b-col sm="12" lg="6" xl="4" class="bg-rosa p-4 text-white">
        <h4><b-icon icon="truck" class="mr-2"></b-icon>Políticas de Entrega</h4>
        <p class="text-justify">
          Una vez realizada, o en paralelo a la verificación de stock, se le
          solicitará que elija el medio de entrega que más le acomode, estos
          normalmente pueden ser:
        </p>
        <b-list-group class="text-left text-burdeo small">
          <b-list-group-item>Retiro en Bosque San Carlos </b-list-group-item>
          <b-list-group-item>Delivery o Envío </b-list-group-item>
        </b-list-group>
        <p class="text-justify">
          El Costo y factibilidad del delivery o envío depende del sector al que
          haya que enviarlo. Para deliverys a domicilio el costo debe incluirse
          en el pago de los productos. En el caso de envíos por empresas como
          Starken o Chilexpress, el pedido será enviado por pagar.
        </p>
      </b-col>
      <b-col sm="12" lg="6" xl="4" class="bg-rosa p-4 text-white">
        <h4><b-icon icon="credit-card-2-front" class="mr-2"></b-icon>Políticas de Pago</h4>
        <p class="text-justify">
          En Sexdúceme contamos con un amplio abanico de posibilidades de pago
        </p>
        <b-list-group class="text-left text-burdeo small">
          <b-list-group-item>Efectivo </b-list-group-item>
          <b-list-group-item>Tarjetas (Débito/Crédito) </b-list-group-item>
          <b-list-group-item>Transferencia</b-list-group-item>
          <b-list-group-item>webPay (Botón de Pago) </b-list-group-item>
        </b-list-group>
        <p class="text-justify">
          Su pedido quedará reservado por 24hrs. a la espera del pago, una vez transcurrido este tiempo y si no ha realizado el pago por cualquiera de los medios indicados, deberá solicitar nuevamente la compra y pasar por el proceso de verificación de stock.
        </p>
        <p class="text-justify small">
          Las opciones de Efectivo y Tarjetas sólo se encuentra disponible para entregas presenciales como "Retiros En Bosque San Carlos" o también en "Ferias".
        </p>
        <p class="text-justify small">
          Los plazos de reserva pueden variar según Fase del Plan paso a paso, o eventos especiales.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style>
</style>