<template>
  <div>
    <!-- Identificación del Consultante -->
    <vue-input-ui
      id="inputFormNombre"
      v-model="formulario.nombre"
      label=""
      size="lg"
      hint="Nombre"
      :error="validacionesFormulario.nombreError"
    ></vue-input-ui>

    <!-- Medio de Respuesta -->
    <b-tabs
      :class="
        validacionesFormulario.contactoError ? 'border border-danger' : ''
      "
    >
      <b-tab disabled>
        <template #title>
          <span class="small">Cómo te Contactamos?</span>
        </template>
      </b-tab>
      <b-tab>
        <template #title>
          <font-awesome-icon
            class="text-whatsapp"
            size="lg"
            :icon="['fab', 'whatsapp']"
          />
        </template>
        <InputWhatsapp
          @ingresado="formulario.cellphone = $event"
          :value="formulario.cellphone"
        ></InputWhatsapp>
      </b-tab>
      <b-tab>
        <template #title>
          <b-icon icon="envelope-open"></b-icon>
        </template>
        <InputEmail
          @ingresado="formulario.email = $event"
          :value="formulario.email"
        ></InputEmail>
      </b-tab>
    </b-tabs>

    <!-- Mensaje del Cliente -->
    <b-form-textarea
      id="textarea-auto-height"
      placeholder=""
      rows="3"
      max-rows="8"
      v-model="formulario.mensaje"
      pill
      :class="validacionesFormulario.mensajeError ? 'border border-danger' : ''"
    ></b-form-textarea>

    <!-- SUBMIT -->

    <b-button @click="enviarMensaje()" pill block class="btn-sexduceme">
      <b-icon icon="menu-button-wide-fill"></b-icon> Enviar
    </b-button>
  </div>
</template>

<script>
import InputWhatsapp from "@/components/inputs/InputWhatsapp";
import InputEmail from "@/components/inputs/InputEmail";
const axios = require("@/assets/functions/axiosClient.js");

export default {
  data() {
    return {
      formulario: {
        nombre: "",
        cellphone: "",
        email: "",
        mensaje: "",
      },
      validacionesFormulario: {
        nombreError: false,
        mensajeError: false,
        contactoError: false,
      },
    };
  },
  components: {
    InputWhatsapp,
    InputEmail,
  },
  methods: {
    enviarMensaje() {
      if (this.validarContenido()) {
        this.showMsgBoxTwo();
      }
    },
    validarContenido() {
      this.validacionesFormulario.nombreError =
        this.formulario.nombre.length < 3;
      this.validacionesFormulario.mensajeError =
        this.formulario.mensaje.length < 3;
      this.validacionesFormulario.contactoError =
        this.formulario.cellphone.length != 11 &&
        this.formulario.email.length === 0;
      return (
        !this.validacionesFormulario.nombreError &&
        !this.validacionesFormulario.mensajeError &&
        !this.validacionesFormulario.contactoError
      );
    },
    makeToast(variant = null, mensaje) {
      this.$bvToast.toast(mensaje, {
        title: "Contactando a Sexdúceme",
        variant: variant,
        solid: true,
      });
    },
    showMsgBoxTwo() {
      const h = this.$createElement;
      // Using HTML string
      // const titleVNode = h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
      // More complex structure
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("p", { class: ["text-center"] }, [
          "A nombre de ",
          h("strong", this.formulario.nombre),
          " se enviará el mensaje: ",
        ]),
        h("p", { class: ["text-center", "font-italic"] }, [
          h("p", '"' + this.formulario.mensaje + '"'),
        ]),
        h("p", { class: ["text-left"] }, [
          "Se le responderá a través de alguno de los siguientes medios:",
        ]),
        h("p", { class: ["text-left"] }, [
          this.formulario.cellphone
            ? "Whatsapp: +" + this.formulario.cellphone
            : "",
        ]),
        h("p", { class: ["text-left"] }, [
          this.formulario.email ? "Email: " + this.formulario.email : "",
        ]),
        h("b-img", {
          props: {
            src: require("@/assets/logo_100x200.png"),
            center: true,
            fluid: true,
          },
        }),
      ]);

      this.$bvModal
        .msgBoxConfirm(messageVNode, {
          title: "Por Favor verifica los datos ingresados",
          size: "md",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Enviar",
          cancelVariant: "danger",
          cancelTitle: "Modificar",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.sending = true;
            axios
              .post("/contactos/mensajes", {
                nombre: this.formulario.nombre,
                email: this.formulario.email,
                whatsapp: this.formulario.cellphone,
                mensaje: this.formulario.mensaje,
              })
              .then((response) => {
                if (response.status === 201) {
                  this.makeToast(
                    "success",
                    "Se ha recibido su mensaje correctamente"
                  );
                  this.formulario = {
                    nombre: "",
                    cellphone: "",
                    email: "",
                    mensaje: "",
                  };
                }
              })
              .catch((err) => {
                this.makeToast(
                  "danger",
                  "No ha podido enviarse el mensaje " + err
                );
              })
              .finally(() => {
                this.sending = false;
              });
          } else {
            console.log("Volver al formulario");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>